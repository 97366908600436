.dateContainer {
  position: fixed;
  top: 10px;
  right: 10px;
  font-family: cursive;
  margin: 2rem;
}
.dateBox:hover {
  cursor: pointer;
}
.dateBox {
  background-color: rgba(235, 235, 235, 0.623);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}
.datesDropdown {
  margin-top: 0.25rem;
  font-size: 1rem;
  background-color: rgba(235, 235, 235, 0.623);
  border-radius: 5px;
  padding: 0.5rem 0rem;
}

.dateDropdownVal {
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid black;
}
.downArrow {
  font-size: 0.9rem;
  transform: translate(0.1rem, 0.1rem);
}
.dateDropdownVal:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.356);
  font-weight: 500;
}
